<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateSiteStyled>
      <InputField :labelWidth="6" class="talpa-id">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.talpaID.$model" placeholder="TalpaID" />
        </template>
        <template v-slot:label>
          <span>TalpaID</span>
        </template>
        <template v-slot:errors v-if="$v.talpaID.$dirty">
          <div class="error" v-if="!$v.talpaID.required">Field is required</div>
          <div class="error" v-if="!$v.talpaID.minLength">Field must have at least {{ $v.talpaID.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="name">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.name.$model" placeholder="Name" />
        </template>
        <template v-slot:label>
          <span>Name</span>
        </template>
        <template v-slot:errors v-if="$v.name.$dirty">
          <div class="error" v-if="!$v.name.required">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="alias">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.alias.$model" placeholder="Alias" />
        </template>
        <template v-slot:label>
          <span>Alias</span>
        </template>
        <template v-slot:errors v-if="$v.alias.$dirty">
          <div class="error" v-if="!$v.alias.required">Field is required</div>
          <div class="error" v-if="!$v.alias.minLength">Field must have at least {{ $v.alias.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="alias">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.countryCode.$model" placeholder="DE" />
        </template>
        <template v-slot:label>
          <span>CountryCode</span>
        </template>
        <template v-slot:errors v-if="$v.countryCode.$dirty">
          <div class="error" v-if="!$v.countryCode.required">Field is required</div>
          <div class="error" v-if="!$v.countryCode.minLength || !$v.countryCode.maxLength">
            Field must have exactly {{ $v.countryCode.$params.minLength.min }} letters.
          </div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="lat">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.lat.$model" placeholder="Center Latitude" />
        </template>
        <template v-slot:label>
          <span>Latitude</span>
        </template>
        <template v-slot:errors v-if="$v.lat.$dirty">
          <div class="error" v-if="!$v.lat.required">Field is required</div>
          <div class="error" v-if="!$v.lat.decimal">Field must be a float/decimal number.</div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="lng">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.lng.$model" placeholder="Center Longitude" />
        </template>
        <template v-slot:label>
          <span>Longitude</span>
        </template>
        <template v-slot:errors v-if="$v.lng.$dirty">
          <div class="error" v-if="!$v.lng.required">Field is required</div>
          <div class="error" v-if="!$v.lng.decimal">Field must be a float/decimal number.</div>
        </template>
      </InputField>
    </CreateSiteStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'

import { required, minLength, maxLength, decimal } from 'vuelidate/lib/validators'

import CREATE_SITE_MUTATION from '#/graphql/sites/createSite.gql'

const CreateSiteStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
`

export default {
  components: {
    CreateSiteStyled,
    ModelCreater,
    InputField,
    // Multiselect,
  },
  data() {
    return {
      talpaID: '',
      name: '',
      alias: '',
      countryCode: '',
      lat: null,
      lng: null,
      errors: [],
      isLoading: false,
      isCreating: false,
    }
  },
  computed: {
    title() {
      return `Create Site`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating
    },
  },
  validations: {
    talpaID: {
      required,
      minLength: minLength(5),
    },
    name: {
      required,
      minLength: minLength(5),
    },
    alias: {
      required,
      minLength: minLength(3),
    },
    countryCode: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
    },
    lat: {
      required,
      decimal,
    },
    lng: {
      required,
      decimal,
    },
  },
  methods: {
    async create() {
      this.isCreating = true
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_SITE_MUTATION,
          variables: {
            data: {
              talpaID: this.talpaID,
              name: this.name,
              alias: this.alias,
              countryCode: this.countryCode,
              centerLat: parseFloat(this.lat),
              centerLng: parseFloat(this.lng),
            },
          },
        })
        const id = get(res, 'data.createSite.id')
        if (id) {
          this.$emit('reload')
          this.$router.push({
            name: 'site',
            params: {
              id,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
      }
      this.isCreating = false
    },
  },
}
</script>
